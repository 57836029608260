import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { FC } from 'react';
import Home from 'src/components/modules/Home';

const AppRoutes: FC = () => {
  return (
    <>
      <Routes>
        <Route  path=":slug/*" element={<Home />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
