import React, { FC, useContext } from "react";
import "./header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";

import { AllContentContext } from "src/context/AllContentContext";
const Footer: FC = () => {
  const context = useContext(AllContentContext);

  interface ContentItem {
    activity?: {
      title?: string;
      activityContent?: {
        subTitle?: string;
      };
      formattedDate?: string;
      location?: {
        name?: string;
        country?: string;
      };
      socialChannels?: {
        facebook?: string;
        twitter?: string;
        youtube?: string;
        instagram?: string;
        linkedin?: string;
      };
      content?: {
        showAheadLogo?: string;
        aheadLogo?: string;
        showClientLogo?: string;
        clientLogo?: string;
        showDescription?: string;
        description?: string;
        showCopyrightMessage?: string;
        copyrightMessage?: string;
        showSocialChannels?: string;
        submissionStatus?: string;
      };
      client?: {
        legalName?: string;
        name?: string;
        logo?: string;
        website?: string;
      };
    };
  }

  // Check if context is undefined and return an error message accordingly
  if (!context) {
    return <div>Error: AllContentContext is not available</div>;
  }

  const { allContent, setAllContent } = context;
  const details = allContent.content as ContentItem;

  // Assuming the first item in the array is what you need
  const firstDetail = details;
  return (
    <>
      {details.activity && (
        <footer className="footer xl:container mx-auto bg-black">
          {details.activity &&
            details.activity.content &&
            details.activity.content.showCopyrightMessage === "Yes" &&
            details.activity?.client &&
            details.activity?.client?.legalName &&
            details.activity.content.copyrightMessage != "" && (
              <div className="leftCol">
                {details.activity &&
                details.activity?.client &&
                details.activity?.client?.website ? (
                  <>
                    Ⓒ {new Date().getFullYear()}{" "}
                    <a target="_blank"
                      className="hover:underline"
                      href={
                        details.activity &&
                        details.activity?.client &&
                        details.activity?.client?.website
                      }
                    >
                      {details.activity &&
                        details.activity?.client &&
                        details.activity?.client?.legalName}
                      ,{" "}
                    </a>
                    {details.activity.content.copyrightMessage}{" "}
                  </>
                ) : (
                  <>
                    Ⓒ {new Date().getFullYear()}{" "}
                    {details.activity &&
                      details.activity?.client &&
                      details.activity?.client?.legalName}
                    , {details.activity.content.copyrightMessage}.{" "}
                  </>
                )}
              </div>
            )}
          {details.activity &&
            details.activity.content &&
            details.activity.content.showSocialChannels === "Yes" && (
              <div className="rightCol socialIcons">
                <ul>
                  {details.activity.socialChannels &&
                    details.activity.socialChannels.twitter != "" && (
                      <li>
                        <a
                          href={details.activity.socialChannels.twitter}
                          className="twitter text-black bg-white hover:bg-magenta-800 hover:text-white"
                        >
                          <FontAwesomeIcon icon={faXTwitter} className="fa" />
                        </a>
                      </li>
                    )}
                  {details.activity.socialChannels &&
                    details.activity.socialChannels.instagram != "" && (
                      <li>
                        <a
                          href={details.activity.socialChannels.instagram}
                          className="instagram text-black bg-white hover:bg-magenta-800 hover:text-white"
                        >
                          <FontAwesomeIcon icon={faInstagram} className="fa" />
                        </a>
                      </li>
                    )}
                  {details.activity.socialChannels &&
                    details.activity.socialChannels.facebook != "" && (
                      <li>
                        <a
                          href={details.activity.socialChannels.facebook}
                          className="facebook text-black bg-white hover:bg-magenta-800 hover:text-white"
                        >
                          <FontAwesomeIcon icon={faFacebookF} className="fa" />
                        </a>
                      </li>
                    )}
                  {details.activity.socialChannels &&
                    details.activity.socialChannels.linkedin != "" && (
                      <li>
                        <a
                          href={details.activity.socialChannels.linkedin}
                          className="linkedin text-black bg-white hover:bg-magenta-800 hover:text-white"
                        >
                          <FontAwesomeIcon icon={faLinkedinIn} className="fa" />
                        </a>
                      </li>
                    )}
                  {details.activity.socialChannels &&
                    details.activity.socialChannels.youtube != "" && (
                      <li>
                        <a
                          href={details.activity.socialChannels.youtube}
                          className="linkedin text-black bg-white hover:bg-magenta-800 hover:text-white"
                        >
                          <FontAwesomeIcon icon={faYoutube} className="fa" />
                        </a>
                      </li>
                    )}
                </ul>
              </div>
            )}
        </footer>
      )}
    </>
  );
};

export default Footer;
