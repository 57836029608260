import React from "react";
import { Field, ErrorMessage, FieldProps, FormikProps } from "formik";

interface Option {
  label: string;
  value: string;
}

interface CMSFormRadioButtonsProps {
  label: string;
  name: string;
  options: Option[];
  form: FormikProps<any>; // Replace `any` with your form values type if known
  required?: boolean;
}

const CMSFormRadioButtons: React.FC<CMSFormRadioButtonsProps> = (props) => {
  const { label, name, options, form, required } = props;

  return (
    <div className="relative">
      {label && label != "" && (
        <label
          htmlFor={name}
          className={` font-lato font-light ${
            required ? "mb-4 mandatory_field ps-2" : ""
          } ps-1 mb-4`}
        >
          {label} {required === true ? <span className="mandatory text-magenta-800 text-xl">*</span> :  <span className="text-xl"></span>}
        </label>
      )}
      <div
        className={`relative ${
          form.touched[name] && form.errors[name]
            ? "is-invalid payment"
            : "payment"
        }`}
      >
        {options.map((option) => (
          <div
            key={option.label}
            className="checkBoxContainer mb-1 pb-1 ms-2 mt-3"
          >
            <label
              className={`${
                form.touched[name] && form.errors[name]
                  ? "isInvalidText radioText"
                  : "radioText"
              }`}
            >
              <Field
                name={name}
                type="radio"
                id={option.value}
                className={`radioButton`}
                value={option.value}
              />
              {option.label}
            </label>
          </div>
        ))}
        {form.touched[name] && form.errors[name] && (
          <span id={`${name}-error`} className="error invalid-feedback">
            <ErrorMessage component="div" name={name} className="form-error" />
          </span>
        )}
      </div>
    </div>
  );
};

export default CMSFormRadioButtons;
