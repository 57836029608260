import React, { useState, useEffect, FC } from "react";
import { Field, ErrorMessage, FieldProps, FormikProps, Form } from "formik";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import axios from "axios";

interface CMSFormTelephoneProps {
  name: string;
  type?: string;
  placeholder?: string;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  className?: string;
  description?: string;
  variant?: string;
  value?: string;
  error?: string;
  children?: React.ReactNode;
  label?: string;
  required?: boolean;
  touched?: boolean;
  errors?: string;
  validate?: (value: string) => void | string | Promise<void | string>;
  as?: string;
  md?: any;
  rows?: number;
  form?: FormikProps<any>;
  controlId?: string;
  options?: any;
  disabled?: boolean;
  readOnly?: boolean;
  ref?: React.Ref<any>;
  errorState?: boolean;
  setErrorState?: (state: boolean) => void;
}

const CMSFormTelephone: FC<CMSFormTelephoneProps> = ({
  name,
  placeholder,
  className,
  description,
  value,
  label,
  required,
  onKeyPress,
  form = {} as FormikProps<any>,
  controlId,
  errorState,
  setErrorState,
  onChange,
  ...props
}) => {
  const formatPhoneNumberOutput = (
    isValid: boolean,
    newNumber: string,
    countryData: any,
    fullNumber: string,
    isExtension: boolean
  ) => {
    return fullNumber.replace(/(\s|-)/g, "");
  };

  const [state, setState] = useState<string>();
  const [items, setItems] = useState<Record<string, string>>({});
  const [preferredCountries, setPreferredCountries] = useState<string[]>([
    "lb",
  ]);
  const [telephoneValid, setTelephoneValid] = useState<boolean>(true);

  const fetchDialCode = () => {
    axios
      .get(
        "https://ipapi.co/json/?key=o9Bmy7K51dx6ChF7iKA8ndWr4uSdmiJsvtjOtLE2OqOukoYYj3"
      )
      .then((response) => {
        const data = response.data;
        setState(data.country.toLowerCase());
      })
      .catch((error) => {
        console.error("Error fetching dial code:", error);
      });
  };

  useEffect(() => {
    const storedItems = localStorage.getItem("constants");
    if (storedItems) {
      setItems(JSON.parse(storedItems));
    }
  }, []);

  useEffect(() => {
    if (!value) {
      fetchDialCode();
    }
  }, [value]);

  const errorMap = [
    "Invalid Phone Number",
    "Invalid Country Code",
    "Please enter complete number",
    "Please enter valid number",
    "Invalid Phone Number",
  ];

  const setValidity = (valid: boolean) => {
    setTelephoneValid(valid);
  };

  const setError = (
    valid: boolean,
    setFieldError: (field: string, message: string | undefined) => void
  ) => {
    if (valid) {
      setErrorState && setErrorState(false);
    } else {
      setErrorState && setErrorState(true);
      setFieldError(name, "Please enter a valid number");
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
    if (onKeyPress) {
      onKeyPress(e as React.KeyboardEvent<HTMLInputElement>);
    }
  };

  return (
    <>
      {label && (
        <label
          htmlFor={name}
          className={`mb-2 font-lato text-md text-textBlack font-semibold`}
        >
          {label}{" "}
          {required === true ? (
            <span className="mandatory text-magenta-800 text-xl">*</span>
          ) : (
            <span className="text-xl"></span>
          )}
        </label>
      )}
      <div className="form-group position-relative" onKeyPress={handleKeyPress}>
        <Field name={name}>
          {({
            field: { value },
            form: { setFieldTouched, setFieldValue, setFieldError },
          }: FieldProps) => (
            <Form>
              <IntlTelInput
                {...props}
                containerClassName={`intl-tel-input `}
                defaultCountry={state ? state : "ae"}
                inputClassName={`form-control  input-textfield bottom-border  customInput ${
                  form.touched[name] && form.errors[name] ? "is-invalid" : ""
                }`}
                defaultValue={value}
                separateDialCode
                nationalMode={false}
                autoHideDialCode={false}
                fieldId={name}
                fieldName={name}
                format={false}
                autoPlaceholder={true}
                preferredCountries={preferredCountries}
                onPhoneNumberBlur={() => {
                  setFieldTouched(name, true);
                }}
                onPhoneNumberChange={(
                  isValid: boolean,
                  phone: string,
                  country: any,
                  fullNumber: string
                ) => {
                  setFieldValue(name, fullNumber);

                  if (phone.length > 0) {
                    setValidity(isValid);
                  }
                  if (phone.length <= 0) {
                    setValidity(true);
                  }

                  setError(isValid, setFieldError);

                  if (onChange) {
                    const event = {
                      target: {
                        name,
                        value: fullNumber,
                      },
                    } as unknown as React.ChangeEvent<HTMLInputElement>;
                    onChange(event);
                  }
                }}
              />
            </Form>
          )}
        </Field>
        {form.errors && form.errors[name] && (
          <span id={`${name}-error`} className="error form-error errorMessage">
            <ErrorMessage name={name} />
          </span>
        )}
        {errorState && (
          <span id={`${name}-error`} className="error form-error errorMessage">
            Enter a Valid Phone Number
          </span>
        )}
      </div>
    </>
  );
};

export default CMSFormTelephone;
