import React, { FC, useContext } from "react";
import "./header.css";
import logo from "../../../../assets/images/ahead_hash_icon.svg";
import ahead from "../../../../assets/images/ahead_logo.svg";
import aheadFull from "../../../../assets/images/aheadlogo.svg";
import { AllContentContext } from "src/context/AllContentContext";

const Header: FC = () => {
  // Access context and handle potential undefined context
  const context = useContext(AllContentContext);

  interface ContentItem {
    activity?: {
      title?: string;
      activityContent?: {
        subTitle?: string;
      };
      formattedDate?: string;
      location?: {
        name?: string;
        country?: string;
      };
      content?: {
        showAheadLogo?: string;
        aheadLogo?: string;
        showClientLogo?: string;
        clientLogo?: string;
        showDescription?: string;
        description?: string;
        showCopyrightMessage?: string;
        copyrightMessage?: string;
        showSocialChannels?: string;
        submissionStatus?: string;
      };
      client?: {
        name?: string;
        logo?: string;
      };
    };
  }

  // Check if context is undefined and return an error message accordingly
  if (!context) {
    return <div>Error: AllContentContext is not available</div>;
  }

  const { allContent, setAllContent } = context;
  const details = allContent.content as ContentItem;

  // Assuming the first item in the array is what you need
  const firstDetail = details;

  return (
    <>
      <header className="xl:container mx-auto bg-magenta-800">
        <div className="headerRightCol ">
          <ul className="headerElementsGrid ">
            {firstDetail.activity &&
              firstDetail.activity.content &&
              firstDetail.activity.content.showAheadLogo === "Yes" &&
              firstDetail.activity.content.aheadLogo != "" && (
                <>
                  <li className="iconHashtag cursor-pointer bg-black"  onClick={() =>
                      setAllContent((prevState) => ({
                        ...prevState,
                        reload: true,
                      }))
                    }>
                    <div>
                      <img src={logo} alt="" />
                    </div>
                  </li>
                  <li className="cursor-pointer" onClick={() =>
                      setAllContent((prevState) => ({
                        ...prevState,
                        reload: true,
                      }))
                    }>
                    <img
                      src={
                        process.env.REACT_APP_BACKEND_URL +
                        "/public/" +
                        firstDetail.activity.content.aheadLogo
                      }
                      alt="Ahead Logo"
                      className="aheadLogo"
                    />
                  </li>
                  <li>
                    <span className="separator font-light">|</span>
                  </li>
                </>
              )}

            <li>
              <p
                className={`applicationTitle font-lato font-light ${
                  firstDetail.activity &&
                  firstDetail.activity.content &&
                  firstDetail.activity.content.showAheadLogo === "No"
                    ? "ps-4"
                    : "ps-4"
                }`}
              >
                On-Site Registration Application
              </p>
            </li>
          </ul>
        </div>
      </header>

      <div className="eventDetailsContainer bg-white xl:container mx-auto">
        {firstDetail.activity && (
          <div className="contentContainer  mx-auto">
            <ul>
              {firstDetail.activity &&
                firstDetail.activity.content &&
                firstDetail.activity.content.showClientLogo ===
                  "Show Client Logo" && (
                  <>
                    <li>
                      <div className="clientLogo">
                        {firstDetail.activity &&
                          firstDetail.activity.client &&
                          firstDetail.activity.client.logo && (
                            <img
                              src={
                                process.env.REACT_APP_BACKEND_URL +
                                "/public/" +
                                firstDetail.activity.client.logo
                              }
                              alt="Client Logo"
                              className="imgResponsive"
                            />
                          )}
                      </div>
                    </li>
                    <li>
                      <span className="eventDetailsSeparator font-lato font-light">
                        |
                      </span>
                    </li>
                  </>
                )}
              {firstDetail.activity &&
                firstDetail.activity.content &&
                firstDetail.activity.content.showClientLogo ===
                  "Show Different Logo" &&
                firstDetail.activity.content.clientLogo != "" && (
                  <>
                    <li>
                      <div className="clientLogo">
                        <img
                          src={
                            process.env.REACT_APP_BACKEND_URL +
                            "/public/" +
                            firstDetail.activity.content.clientLogo
                          }
                          alt="Client Logo"
                          className="imgResponsive"
                        />
                      </div>
                    </li>
                    <li>
                      <span className="eventDetailsSeparator font-lato font-light">
                        |
                      </span>
                    </li>
                  </>
                )}
              <li>
                <div className="eventDetails">
                  <div className="eventTitle font-lato leading-8 font-semibold">
                    {firstDetail?.activity?.title}
                    <div className="subTitle font-lato opacity-95 mt-2">
                      {firstDetail?.activity?.activityContent?.subTitle}
                    </div>
                  </div>
                  <div className="eventDateLocation margintop5">
                    <span className="eventDate font-lato">
                      {firstDetail?.activity?.formattedDate}
                    </span>
                    <span className="eventLocation font-lato">
                      {firstDetail?.activity?.location?.name
                        ? `, ${firstDetail?.activity?.location?.name}`
                        : ""}
                      {firstDetail?.activity?.location?.country
                        ? `, ${firstDetail?.activity?.location?.country}`
                        : ""}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
