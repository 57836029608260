import React, { FC, useContext } from "react";
import Header from "../../partials/Header";
import Footer from "../../partials/Footer";
import "../../partials/header.css";
import logo from "../../../../../assets/images/ahead_hash_icon.svg";
import ahead from "../../../../../assets/images/ahead_logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { AllContentContext } from "src/context/AllContentContext";
const ErrorPage: FC = () => {
  const context = useContext(AllContentContext);

  interface ContentItem {
    activity?: {
      title?: string;
      activityContent?: {
        subTitle?: string;
      };
      formattedDate?: string;
      location?: {
        name?: string;
        country?: string;
      };
      content?: {
        showAheadLogo?: string;
        aheadLogo?: string;
        showClientLogo?: string;
        clientLogo?: string;
        showDescription?: string;
        description?: string;
        showCopyrightMessage?: string;
        copyrightMessage?: string;
        showSocialChannels?: string;
        submissionStatus?: string;
      };
      client?: {
        name?: string;
        logo?: string;
      };
    };
  }

  // Check if context is undefined and return an error message accordingly
  if (!context) {
    return <div>Error: AllContentContext is not available</div>;
  }

  const { allContent, setAllContent } = context;
  const details = allContent.content as ContentItem;

  // Assuming the first item in the array is what you need
  const firstDetail = details;

  return (
    <>
      <header className="xl:container mx-auto bg-magenta-800">
        <div className="headerRightCol ">
          <ul className="headerElementsGrid ">
            {firstDetail.activity &&
              firstDetail.activity.content &&
              firstDetail.activity.content.showAheadLogo === "Yes" &&
              firstDetail.activity.content.aheadLogo != "" && (
                <>
                  <li className="iconHashtag bg-black">
                    <div>
                      <img src={logo} alt="" />
                    </div>
                  </li>
                  <li>
                    <img
                      src={
                        process.env.REACT_APP_BACKEND_URL +
                        "/public/" +
                        firstDetail.activity.content.aheadLogo
                      }
                      alt="Ahead Logo"
                      className="aheadLogo"
                    />
                  </li>
                  <li>
                    <span className="separator font-light">|</span>
                  </li>
                </>
              )}

            <li>
              <p
                className={`applicationTitle font-lato font-light ${
                  firstDetail.activity &&
                  firstDetail.activity.content &&
                  firstDetail.activity.content.showAheadLogo === "No"
                    ? "ps-4"
                    : "ps-4"
                }`}
              >
                On-Site Registration Application
              </p>
            </li>
          </ul>
        </div>
      </header>
      <div className="formHolderSection  flex flex-col items-center justify-center ">
        <div className="successMsg flex flex-col items-center justify-center py-10 my-10">
          <div className="animated_icon_holder"></div>
          <FontAwesomeIcon
            icon={faCircleExclamation}
            className="fa text-5xl text-gray mb-5"
          />
          <p className="heading font-lato text-3xl text-magenta-800 font-base mb-2">
            Something Went Wrong!
          </p>
          <p className="text-base font-lato">Try refreshing the page</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ErrorPage;
