type State = {
  data: Record<string, unknown>;
  isLoading: boolean;
  status: "fetching" | "fetched" | "fetchFailed" | "submitting" | "submitFailed" | "submitted";
  error: string;
  isSubmitting?: boolean;
  isSubmitted?: boolean;
};

type Action =
  | { type: "FETCH_REQUEST" }
  | { type: "FETCH_SUCCESS"; payload: any }
  | { type: "FETCH_FAILURE"; payload: string }
  | { type: "FORM_SUBMISSION_REQUEST" }
  | { type: "FORM_SUBMISSION_FAILURE"; payload: string }
  | { type: "FORM_SUBMISSION_SUCCESS"; payload: Record<string, unknown> }
  | { type: "FORM_INITIAL_STATE" };

export const createInitialState: State = {
  data: {},
  isLoading: true,
  status: "fetching",
  error: "",
};

export const createReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return {
        ...state,
        isLoading: true,
        status: "fetching",
      };

    case "FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        status: "fetched",
        data: action.payload,
      };

    case "FETCH_FAILURE":
      return {
        ...state,
        isLoading: false,
        status: "fetchFailed",
        error: action.payload,
      };

    case "FORM_SUBMISSION_REQUEST":
      return {
        ...state,
        isSubmitting: true,
        
        status: "submitting",
        error: "",
      };

    case "FORM_SUBMISSION_FAILURE":
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        status: "submitFailed",
        error: action.payload,
      };

    case "FORM_SUBMISSION_SUCCESS":
      return {
        ...state,
        isSubmitting: false,
        isSubmitted: true,
        status: "submitted",
        data: action.payload, // Update the state with the payload data
      };

    case "FORM_INITIAL_STATE":
      return {
        ...state,
        isLoading: false,
        status: "fetched",
        error: "",
        data: {}, // Reset the data to initial state
      };

    default:
      return state;
  }
};
