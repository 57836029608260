import { BrowserRouter, Router } from "react-router-dom";
import AppRoutes from "./config/appRoutes";


const App: React.FC = () => {
  return (
    <>
      <AppRoutes />
    </>
  );
};

export default App;
