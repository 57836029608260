import React, { FC, ReactNode, useReducer, useContext, useEffect } from "react";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import { AllContentContext } from "src/context/AllContentContext";

// Define the type for the component props
interface LayoutProps {
  children: ReactNode;
  className?: string; // Renamed from 'class'
}

// Ensure that the AllContentContext is not undefined
const Layout: FC<LayoutProps> = ({ children, className }) => {
  // Renamed parameter from 'class' to 'className'
  // Access context and handle potential undefined context
  const context = useContext(AllContentContext);

  interface ContentItem {
    activity?: {
      title?: string;
      activityContent?: {
        subTitle?: string;
      };
      formattedDate?: string;
      location?: {
        name?: string;
        country?: string;
      };
      content?: {
        showAheadLogo?: string;
        aheadLogo?: string;
        showClientLogo?: string;
        clientLogo?: string;
        showDescription?: string;
        description?: string;
        showCopyrightMessage?: string;
        copyrightMessage?: string;
        showSocialChannels?: string;
        submissionStatus?: string;
      };
      client?: {
        name?: string;
        logo?: string;
      };
    };
  }

  // Check if context is undefined and return an error message accordingly
  if (!context) {
    return <div>Error: AllContentContext is not available</div>;
  }

  const { allContent, setAllContent } = context;
  const details = allContent.content as ContentItem;

  // Assuming the first item in the array is what you need
  const firstDetail = details;

  return (
    <>
      <Header />
      <div className="xl:container mx-auto">
        {firstDetail.activity &&
          firstDetail.activity.content &&
          firstDetail.activity.content.showDescription === "Yes" &&
          firstDetail.activity.content.description != "" && (
            <>
              <div className="p-4 contentContainer">
                <div className="">
                  {typeof firstDetail.activity.content?.description ===
                  "string" ? (
                    <p
                      className="text-base font-lato"
                      dangerouslySetInnerHTML={{
                        __html: firstDetail.activity.content?.description,
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </>
          )}

        <div className={`formHolderSection ${className || ""}`}>
          {" "}
          {/* Use className here */}{" "}
          {/* Default to empty string if className is undefined */}
          <div className="">{children}</div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Layout;
