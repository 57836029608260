import React, { FC, useContext } from "react";
import { AllContentContext } from "src/context/AllContentContext";

const WelcomeScreen: FC = () => {
  const context = useContext(AllContentContext);

  interface ContentItem {
    activity?: {
      _id?: string;
      title?: string;
      content?: {
        subTitle?: string;
      };
      formattedDate?: string;
      location?: {
        name?: string;
        country?: string;
      };
      welcomeScreen?: {
        showWelcomeScreen?: string;
        screenHeading?: string;
        screenMessage?: string;
      };
      peopleGroups?: [{ title?: string }];
      client?: {
        name?: string;
        logo?: string;
      };
      partner?: [{ name?: string; _id?: string }];
      sponsor?: [{ name?: string; _id?: string }];
    };
  }
  if (!context) {
    return <div>Error: context is not available</div>;
  }

  const { allContent, setAllContent } = context;
  const details = allContent.content as ContentItem;
  const firstDetail = details;
  return (
    <>
      <div className="flex flex-col items-center justify-center">
        {details &&
          details.activity &&
          details.activity.welcomeScreen &&
          details.activity.welcomeScreen.screenHeading && (
            <p className="heading text-2xl text-magenta-800 font-lato text-center">
              {details.activity?.welcomeScreen?.screenHeading}
            </p>
          )}
        {details &&
          details.activity &&
          details.activity.welcomeScreen &&
          details.activity.welcomeScreen.screenMessage && (
            <>
              {typeof details.activity.welcomeScreen.screenMessage ===
              "string" ? (
                <p
                  className="text-lg font-lato mt-3 text-center"
                  dangerouslySetInnerHTML={{
                    __html: details.activity.welcomeScreen.screenMessage,
                  }}
                />
              ) : null}
            </>
          )}
        <div className="btn_holder mt-10">
          <div
            onClick={() => {
              setAllContent((prevState) => ({
                ...prevState,
                loading: true,
              }));
              setTimeout(() => {
                setAllContent((prevState) => ({
                  ...prevState,
                  peopleGroup: "",
                  activeSection: "groupSelection",
                }));
              }, 400);
            }}
            className="formSubmissionButton cursor-pointer bg-magenta-800 hover:bg-magenta-900 py-2 text-xl font-lato px-5 text-white"
          >
            Register
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomeScreen;
