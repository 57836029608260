import React, { FC, useContext } from "react";
import "./groupSelection.css";
import { AllContentContext } from "src/context/AllContentContext";
import Loading from "src/components/UI/layout/shared/loading/Loading";
const GroupSelection: FC = () => {
  const context = useContext(AllContentContext);

  interface ContentItem {
    activity?: {
      _id?: string;
      title?: string;
      content?: {
        subTitle?: string;
      };
      formattedDate?: string;
      location?: {
        name?: string;
        country?: string;
      };
      peopleGroups?: 
        { heading?: string; message?: string; peopleGroups?: [] }
      ;
      client?: {
        name?: string;
        logo?: string;
      };
      partner?: [{ name?: string; _id?: string }];
      sponsor?: [{ name?: string; _id?: string }];
    };
  }
  if (!context) {
    return <div>Error: context is not available</div>;
  }

  const { allContent, setAllContent } = context;
  const details = allContent.content as ContentItem;
  const firstDetail = details;
  return (
    <>
      {allContent.loading && <Loading />}
      {firstDetail.activity && (
        <div className="selectGroup">
          <div className="peopleGroupScreen w-dvw">
            {/* <div className="btn-refresh">Refresh</div> */}

            <div className="customMsg font-lato ">
              {firstDetail.activity &&
                firstDetail.activity.peopleGroups &&
                firstDetail.activity.peopleGroups && (
                  <>
                    <p className="heading text-2xl text-magenta-800">
                      {firstDetail.activity.peopleGroups.heading}
                    </p>
                    {typeof firstDetail.activity.peopleGroups.message ===
                    "string" ? (
                      <p
                        className="text mt-2"
                        dangerouslySetInnerHTML={{
                          __html: firstDetail.activity.peopleGroups.message,
                        }}
                      />
                    ) : null}
                  </>
                )}
              <div className="formGroupContent">
                <div className="formContentHolder">
                  <div className="peopleGroupHolder relative w-dvw">
                    {firstDetail.activity &&
                      firstDetail.activity.peopleGroups &&
                      firstDetail.activity.peopleGroups.peopleGroups &&
                      firstDetail.activity.peopleGroups.peopleGroups.map(
                        (item,i) => {
                          if (item === "Customers")
                            return (
                              <div  key={i} 
                                className="btn btn-theme-secondary py-2 cursor-pointer  border-2 uppercase ng-scope"
                                onClick={() => {
                                  setAllContent((prevState) => ({
                                    ...prevState,
                                    loading: true,
                                  }));
                                  setTimeout(() => {
                                    setAllContent((prevState) => ({
                                      ...prevState,
                                      peopleGroup: "Customers",
                                      activeSection: "formFilling",
                                      company:"",
                                      data: {
                                        ...prevState.data,
                                        company: ``,
                                        activityId:
                                          firstDetail.activity &&
                                          firstDetail.activity._id,
                                        peopleGroup: "Customers",
                                      },
                                    }));
                                  }, 400);
                                }}
                              >
                                Customers
                              </div>
                            );
                          else if (item === "Owners")
                            return (
                              <>
                                {firstDetail.activity &&
                                  firstDetail.activity.client &&
                                  firstDetail.activity.client.name && (
                                    <div key={i} 
                                      className="btn btn-theme-secondary py-2 cursor-pointer border-2 uppercase ng-scope"
                                      onClick={() => {
                                        setAllContent((prevState) => ({
                                          ...prevState,
                                          loading: true,
                                        }));
                                        setTimeout(() => {
                                          setAllContent((prevState) => ({
                                            ...prevState,
                                            peopleGroup: "Owners",
                                            company: `${
                                              firstDetail.activity &&
                                              firstDetail.activity.client &&
                                              firstDetail.activity.client.name
                                            }`,
                                            activeSection: "formFilling",
                                            data: {
                                              ...prevState.data,
                                              company: `${
                                                firstDetail.activity &&
                                                firstDetail.activity.client &&
                                                firstDetail.activity.client.name
                                              }`,
                                              activityId:
                                                firstDetail.activity &&
                                                firstDetail.activity._id,
                                              peopleGroup: "Owners",
                                            },
                                          }));
                                        }, 400);
                                      }}
                                    >
                                      {firstDetail.activity.client.name}
                                    </div>
                                  )}
                              </>
                            );
                          else if (item === "Partners")
                            return (
                              <>
                                {firstDetail.activity &&
                                  firstDetail.activity.partner &&
                                  firstDetail.activity.partner.length > 0 &&
                                  firstDetail.activity.partner.map(
                                    (partner) => {
                                      return (
                                        <div key={i} 
                                          className="btn btn-theme-secondary py-2 cursor-pointer border-2 uppercase ng-scope"
                                          onClick={() => {
                                            setAllContent((prevState) => ({
                                              ...prevState,
                                              loading: true,
                                            }));
                                            setTimeout(() => {
                                              setAllContent((prevState) => ({
                                                ...prevState,
                                                peopleGroup: "Partners",
                                                company: `${partner.name}`,
                                                activeSection: "formFilling",
                                                data: {
                                                  ...prevState.data,
                                                  company: `${partner.name}`,
                                                  activityId:
                                                    firstDetail.activity &&
                                                    firstDetail.activity._id,
                                                  partnerId: partner._id,
                                                  peopleGroup: "Partners",
                                                },
                                              }));
                                            }, 400);
                                          }}
                                        >
                                          {partner.name}
                                        </div>
                                      );
                                    }
                                  )}

                                {firstDetail.activity &&
                                  firstDetail.activity.partner &&
                                  firstDetail.activity.partner.length <= 0 && (
                                    <div
                                      className="btn btn-theme-secondary py-2 cursor-pointer border-2 uppercase ng-scope"
                                      onClick={() => {
                                        setAllContent((prevState) => ({
                                          ...prevState,
                                          loading: true,
                                        }));
                                        setTimeout(() => {
                                          setAllContent((prevState) => ({
                                            ...prevState,
                                            peopleGroup: "Partners",
                                            company: ``,
                                            activeSection: "formFilling",
                                            data: {
                                              ...prevState.data,
                                              company: ``,
                                              activityId:
                                                firstDetail.activity &&
                                                firstDetail.activity._id,
                                              partnerId: "",
                                              peopleGroup: "Partners",
                                            },
                                          }));
                                        }, 400);
                                      }}
                                    >
                                      partner
                                    </div>
                                  )}
                              </>
                            );
                          else if (item === "Sponsors")
                            return (
                              <>
                                {firstDetail.activity &&
                                  firstDetail.activity.sponsor &&
                                  firstDetail.activity.sponsor.length > 0 &&
                                  firstDetail.activity.sponsor.map(
                                    (sponsor) => {
                                      return (
                                        <div key={i} 
                                          className="btn btn-theme-secondary py-2 cursor-pointer border-2 uppercase ng-scope"
                                          onClick={() => {
                                            setAllContent((prevState) => ({
                                              ...prevState,
                                              loading: true,
                                            }));
                                            setTimeout(() => {
                                              setAllContent((prevState) => ({
                                                ...prevState,
                                                peopleGroup: "Sponsors",
                                                company: `${sponsor.name}`,
                                                activeSection: "formFilling",
                                                data: {
                                                  ...prevState.data,
                                                  company: `${sponsor.name}`,
                                                  activityId:
                                                    firstDetail.activity &&
                                                    firstDetail.activity._id,
                                                  sponsorId: sponsor._id,
                                                  peopleGroup: "Sponsors",
                                                },
                                              }));
                                            }, 400);
                                          }}
                                        >
                                          {sponsor.name}
                                        </div>
                                      );
                                    }
                                  )}
                                {firstDetail.activity &&
                                  firstDetail.activity.sponsor &&
                                  firstDetail.activity.sponsor.length <= 0 && (
                                    <div
                                      className="btn btn-theme-secondary py-2 cursor-pointer border-2 uppercase ng-scope"
                                      onClick={() => {
                                        setAllContent((prevState) => ({
                                          ...prevState,
                                          loading: true,
                                        }));
                                        setTimeout(() => {
                                          setAllContent((prevState) => ({
                                            ...prevState,
                                            peopleGroup: "Sponsors",
                                            company: ``,
                                            activeSection: "formFilling",
                                            data: {
                                              ...prevState.data,
                                              company: ``,
                                              activityId:
                                                firstDetail.activity &&
                                                firstDetail.activity._id,
                                              sponsorId: "",
                                              peopleGroup: "Sponsors",
                                            },
                                          }));
                                        }, 400);
                                      }}
                                    >
                                      Sponsor
                                    </div>
                                  )}
                              </>
                            );
                        }
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GroupSelection;
