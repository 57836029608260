import React from "react";
import { Field, ErrorMessage, FieldProps } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

interface Option {
  value: string | number;
  label: string;
}

interface CMSFormSelectProps {
  label?: string;
  name: string;
  form: FieldProps["form"];
  options: Option[];
  selected?: string | number;
  autoFocus?: boolean;
  placeholder?: Option;
  required?: boolean;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  filter?: boolean;
}

const CMSFormSelect: React.FC<CMSFormSelectProps> = ({
  label,
  name,
  form,
  options,
  selected,
  autoFocus,
  placeholder,
  required,
  onKeyPress,
  onChange,
  filter,
  ...rest
}) => {
  return (
    <div className="form-group position-relative">
      {label && (
        <div className="flex items-center justify-between">
          <label
            htmlFor={name}
            className={` font-lato text-md text-textBlack font-semibold`}
          >
            {label}{" "}
            {required === true ? (
              <span className="mandatory text-magenta-800 text-xl">*</span>
            ) : (
              <span className="text-xl"></span>
            )}
          </label>
          <div className="dropIcon text-textBlack">
            <FontAwesomeIcon icon={faChevronDown} className="fa mr-2" />
          </div>
        </div>
      )}

      <Field
        as="select"
        autoFocus={autoFocus}
        id={name}
        value={form.values[name]}
        name={name}
        onKeyPress={onKeyPress}
        onChange={onChange}
        className={`form-control input-textfield bottom-border font-lato text-md  ${
          filter ? "filterPartial" : "customInput"
        } select ${
          form.touched[name] && form.errors[name] ? "is-invalid" : ""
        }`}
        {...rest}
      >
        {placeholder && (
          <option
            className="selectOption font-lato text-md px-3"
            value={placeholder.value}
          >
            {placeholder.label}
          </option>
        )}
        {options.map((option) => (
          <option
            key={option.value}
            className="selectOption px-3"
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </Field>
      {required && <div className="required"></div>}
      {form.touched[name] && form.errors[name] && (
        <span id={`${name}-error`} className="errorMessage form-error">
          <ErrorMessage name={name} />
        </span>
      )}
    </div>
  );
};

export default CMSFormSelect;
