import axios, { AxiosInstance } from 'axios';

interface User {
    token: string;
}

const user: User | null = null; // Assuming user is obtained from some context or state

const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL as string,
    // headers: {
    //     'Authorization': user && user.token ? `Bearer ${user.token}` : ''
    // }
});

export default axiosInstance;
