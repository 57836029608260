type Action = 
    | { type: 'FETCH_REQUEST' }
    | { type: 'FETCH_SUCCESS', payload: any[] }
    | { type: 'FETCH_FAILURE', payload: any };

interface State {
    data: any[];
    isFetching: boolean;
    hasError: boolean;
    error: string;
}

export const detailsInitialState: State = {
    data: [],
    isFetching: true,
    hasError: false,
    error: '',
};

export const detailsReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "FETCH_REQUEST":
            return {
                ...state,
                isFetching: true,
                hasError: false
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isFetching: false,
                data: action.payload
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                hasError: true,
                isFetching: false,
                error: action.payload
            };
        default:
            return state;
    }
};
