import React, { FC, useContext, useReducer, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { AllContentContext } from "src/context/AllContentContext";
import { createInitialState, createReducer } from "src/reducers/createReducer";
import axios from "src/config/axios";
import SuccessMessage from "src/components/UI/layout/shared/message/SuccessMessage";
import Loading from "src/components/UI/layout/shared/loading/Loading";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
interface EventBreakout {
  _id: string;
  day: number;
  title: string;
  breakoutId: string;
  sortOrder: number;
  sessionTitle: string;
}
interface ContentItem {
  activity?: {
    title?: string;
    content?: {
      subTitle?: string;
    };
    formattedDate?: string;
    location?: {
      name?: string;
      country?: string;
    };
    peopleGroups?: [{ title?: string }];
    client?: {
      name?: string;
      logo?: string;
    };
    eventBreakouts?: EventBreakout[];
    breakoutSettings?: {
      showBreakoutScreen?: string;
      heading?: string;
      message?: string;
      validationType: string;
      peopleGroups?: string[];
    };
    formScreen?: {
      showCompleteForm?: string[];
      lighterFormFields: string[];
      heading?: string;
      message?: string;
    };
    countries?: [{ name?: string }];
    partner?: [{ name?: string; logo?: string }];
    sponsor?: [{ name?: string; logo?: string }];
  };
}

interface AllContentData {
  firstName: string;
  lastName: string;
  company: string;
  country: string;
}

interface AllContent {
  content: ContentItem;
  data: AllContentData;
}

const ConfirmAndSubmit: FC = () => {
  const [state, dispatch] = useReducer(createReducer, createInitialState);
  const context = useContext(AllContentContext);
  useEffect(() => {
    dispatch({
      type: "FETCH_SUCCESS",
      payload: "",
    });
  }, []);

  const handleSubmit = async () => {
    dispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    setTimeout(async () => {
      try {
        const res = await axios.post(`add`, allContent.data);

        dispatch({
          type: "FORM_SUBMISSION_SUCCESS",
          payload: res.data,
        });
      } catch (error: any) {
        const status = error?.response?.status || 500;
        const errorMessage =
          status === 400 ? error?.response?.data?.errorMessage : error?.message;
        dispatch({
          type: "FORM_SUBMISSION_FAILURE",
          payload: errorMessage,
        });

        setTimeout(() => {
          dispatch({
            type: "FORM_INITIAL_STATE",
          });
        }, 5000);
      }
    }, 1000);
  };

  if (!context) {
    return <div>Error: context is not available</div>;
  }

  const { allContent, setAllContent } = context;
  const details = allContent.content as ContentItem;
  const data = allContent.data as AllContentData;
  return (
    <>
      {state.isSubmitted ? (
        <SuccessMessage />
      ) : (
        <div className="form_container relative two-column formStyle-Tablet formStyle-TabletLandscape formStyle-Mobile py-12">
          {state.isSubmitting && <Loading />}
          <form
            className="onsiteRegistrationForm ng-dirty ng-valid"
            name="onsiteRegistrationForm"
            id="onsiteRegistrationForm"
          >
            <div className="errorMsg textleft margintop20 marginbottom50 hidden">
              <p className="text">
                <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span className="msgtext">Action failed, try again later.</span>
              </p>
            </div>

            <div className="form_steps_button_wrapper">
              <div className="screen_03">
                <div className="formgroup preview_and_submit ">
                  <div className="formgroup_heading text-magenta-800 font-lato font-light mb-7">
                    <FontAwesomeIcon icon={faPaperPlane} className="fa mr-2" />
                    Confirm &amp; Submit
                  </div>
                  <div className="formgroup-content mx-7">
                    <div className="form_content_holder column_grid_one">
                      <div className="col_left bg-lightestGray py-7 px-8 mb-4">
                        <div className="heading text-magenta-800 font-lato font-bold mb-3">
                          Attendee's Information
                        </div>
                        {allContent.data && (
                          <ul>
                            {data.firstName && (
                              <li className="">
                                <span className="grid_heading text-black font-lato text-md font-bold ">
                                  First Name:
                                </span>{" "}
                                {data.firstName}
                              </li>
                            )}
                            {data.lastName && (
                              <li className="">
                                <span className="grid_heading text-black font-lato text-md font-bold ">
                                  Last Name:
                                </span>{" "}
                                {data.lastName}
                              </li>
                            )}
                            {data.company && (
                              <li className="">
                                <span className="grid_heading text-black font-lato text-md font-bold ">
                                  Company:
                                </span>{" "}
                                {data.company}
                              </li>
                            )}
                            {data.country && (
                              <li>
                                <span className="grid_heading text-black font-lato text-md font-bold ">
                                  Country:
                                </span>{" "}
                                {data.country}
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="btn_holder flex items-center justify-start form_button_holder mx-7 mt-8">
                <div
                  onClick={() => {
                    if (
                      details.activity?.breakoutSettings?.showBreakoutScreen ===
                        "Yes (to all People Groups)" &&
                      (details.activity.eventBreakouts?.length ?? 0) > 0
                    ) {
                      setAllContent((prevState) => ({
                        ...prevState,
                        activeSection: "sessions",
                      }));
                    } else if (
                      details.activity?.breakoutSettings?.showBreakoutScreen ===
                        "Yes (only to selected People Groups)" &&
                      (details.activity.eventBreakouts?.length ?? 0) > 0 &&
                      details.activity?.breakoutSettings?.peopleGroups?.includes(
                        allContent.peopleGroup
                      )
                    ) {
                      setAllContent((prevState) => ({
                        ...prevState,
                        activeSection: "sessions",
                      }));
                    } else {
                      setAllContent((prevState) => ({
                        ...prevState,
                        activeSection: "formFilling",
                      }));
                    }
                  }}
                  className="btn cursor-pointer btn-theme-secondary uppercase bg-textBlack hover:bg-black py-2 px-5 text-xl font-lato text-white mr-5"
                >
                  Back
                </div>
                <div
                  onClick={() => handleSubmit()}
                  className="btn cursor-pointer btn-theme uppercase bg-magenta-800 hover:bg-magenta-900 py-2 px-5 text-xl font-lato text-white mr-5"
                >
                  Confirm &amp; Submit
                </div>
              </div>
              {state.status === "submitFailed" && (
                <div className="mx-7 text-red text-base font-lato mt-4">
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="fa mr-2 text-red text-base font-lato"
                  />
                  Action Failed, Try Again!
                </div>
              )}
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default ConfirmAndSubmit;
