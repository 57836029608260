import { ErrorMessage, Field } from "formik";
import React, { FC } from "react";

interface CMSFormInputProps {
  name: string;
  label?: string;
  form: {
    values: { [key: string]: any };
    touched: { [key: string]: boolean };
    errors: { [key: string]: string };
  };
  type?: string;
  value?: string;
  disabled?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  description?: string;
  placeholder?: string;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  helpMessage?: string;
  [key: string]: any; // For any additional props
}

const CMSFormInput: FC<CMSFormInputProps> = (props) => {
  const {
    name,
    label,
    form,
    type,
    value,
    disabled,
    required,
    autoFocus,
    description,
    placeholder,
    onKeyPress,
    helpMessage,
    ...rest
  } = props;

  return (
    <>
      {label && (
        <label htmlFor={name} className={`mb-2 ${disabled ? 'text-gray opacity-70' :''} font-lato text-md text-textBlack font-semibold`}>
          {label} {required === true ? <span className="mandatory text-magenta-800 text-xl">*</span> :  <span className="text-xl"></span>}
        </label>
      )}
      <div className="form-group position-relative">
        <Field
          name={name}
          value={value || form.values[name]}
          autoFocus={autoFocus}
          disabled={disabled}
          onKeyPress={onKeyPress}
          type={type || "text"}
          placeholder={placeholder}
          className={`form-control input-textfield ${disabled ? 'text-gray opacity-70' :''} font-lato bottom-border ${
            form.touched[name] && form.errors[name] ? "is-invalid" : ""
          }`}
          {...rest}
        />
        {required === true && <div className="required"></div>}

        {form.touched[name] && form.errors[name] && (
          <span id={`${name}-error`} className="form-error">
            <ErrorMessage name={name} />
          </span>
        )}
      </div>
    </>
  );
};

export default CMSFormInput;
