import React, { FC, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faListSquares,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { AllContentContext } from "src/context/AllContentContext";
import CMSFormRadioButtons from "src/components/UI/layout/shared/formFields/CMSFormRadioButtons";
import { Form, Formik } from "formik";
import * as yup from "yup";

interface EventBreakout {
  _id: string;
  day: number;
  title: string;
  breakoutId: string;
  sortOrder: number;
  sessionTitle: string;
}

interface ContentItem {
  activity?: {
    title?: string;
    eventBreakouts?: EventBreakout[];
    breakoutSettings?: {
      heading?: string;
      message?: string;
      validationType: string;
    };
  };
}

interface AllContentData {
  firstName: string;
  lastName: string;
  company: string;
  country: string;
  breakoutIds: string[]; // Add this line to define breakoutIds
}
interface Session {
  _id: string;
  // Add other properties as necessary
}

interface AllContent {
  content: ContentItem;
  data: AllContentData;
}

interface GroupedSessions {
  [day: number]: {
    [title: string]: EventBreakout[];
  };
}

const SessionSelection: FC = () => {
  const context = useContext(AllContentContext);

  if (!context) {
    return <div>Error: context is not available</div>;
  }

  const { allContent, setAllContent } = context;
  const details = allContent.content as ContentItem;
  const data = allContent.data as AllContentData;
  const breakoutIdArray = data.breakoutIds || [];
  // Group sessions by day, then by title
  const groupSessions = (sessions: EventBreakout[]): GroupedSessions => {
    return sessions.reduce((grouped, session) => {
      // Group by day
      if (!grouped[session.day]) {
        grouped[session.day] = {};
      }
      // Group by title
      if (!grouped[session.day][session.title]) {
        grouped[session.day][session.title] = [];
      }
      grouped[session.day][session.title].push(session);
      return grouped;
    }, {} as GroupedSessions);
  };

  // Group the sessions
  const groupedSessions = groupSessions(details.activity?.eventBreakouts || []);
  console.log(groupedSessions);

  const handleFormatArray = (item: EventBreakout[]) => {
    return item.map((session) => ({
      label: session.sessionTitle,
      value: session._id,
    }));
  };


  const initialValuesSaved = Object.entries(groupedSessions).reduce(
    (values, [day, titles]) => {
        Object.keys(titles).forEach((title) => {
            const session: Session | undefined = titles[title].find((session: Session) => breakoutIdArray.includes(session._id));
            values[title] = session ? session._id : ""; 
        });
        return values;
    },
    {} as Record<string, string>
);

  const initialValues = Object.entries(groupedSessions).reduce(
    (values, [day, titles]) => {
      Object.keys(titles).forEach((title) => {
        values[title] = ""; 
      });
      return values;
    },
    {} as Record<string, string>
  );
  const validationSchema = yup.object().shape(
    Object.entries(groupedSessions).reduce((schema, [day, titles]) => {
      Object.keys(titles).forEach((title) => {
        if (
          details.activity?.breakoutSettings?.validationType ===
          "One For Each Day"
        ) {
          schema[title] = yup.string().required(`Please select a breakout`);
        }
      });
      return schema;
    }, {} as Record<string, any>)
  );
  return (
    <div className="form_container relative two-column formStyle-Tablet formStyle-TabletLandscape formStyle-Mobile py-12">
      <div className="errorMsg textleft margintop20 marginbottom50 hidden">
        <p className="text">
          <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span className="msgtext">Action failed, try again later.</span>
        </p>
      </div>

      <div className="form_steps_button_wrapper">
        <div className="screen_03">
          <div className="formgroup preview_and_submit ">
            <div className="formgroup_heading text-magenta-800 font-lato font-light">
              <FontAwesomeIcon icon={faListSquares} className="fa mr-2" />
              {details.activity?.breakoutSettings && (
                <>
                  {details.activity.breakoutSettings.heading}
                  {details.activity.breakoutSettings.message && (
                    <p
                      className="formgroup_subheading text-black mb-10 -mt-3 font-lato"
                      dangerouslySetInnerHTML={{
                        __html: details.activity.breakoutSettings.message,
                      }}
                    />
                  )}
                </>
              )}
            </div>
            <div className="formgroup-content mx-7">
              <div className="form_content_holder column_grid_one">
                <div className="session-display">
                  <Formik
                    initialValues={initialValuesSaved}
                    validationSchema={validationSchema}
                    enableReinitialize={false}
                    validateOnChange={true}
                    validateOnBlur={true}
                    onSubmit={(values) => {
                      setAllContent((prevState) => ({
                        ...prevState,
                        loading: true,
                      }));
                      const sessionArray = Object.entries(values)
                        .filter(([key, value]) => value !== "")
                        .map(([key, value]) => value);
                      console.log(sessionArray);
                      setTimeout(() => {
                        const data = {
                          ...allContent.data,
                          breakoutIds: sessionArray,
                        };
                        setAllContent((prevState) => ({
                          ...prevState,
                          loading: true,
                          data: data,
                          activeSection: "detailsDisplay",
                        }));
                      }, 400);
                    }}
                  >
                    {(form) => (
                      <Form>
                        <div className="grid grid-cols-1 gap-x-7 gap-y-3">
                         <>{console.log(initialValuesSaved,initialValues)}</> 
                          {Object.entries(groupedSessions).map(
                            ([day, titles]) => (
                              <div key={day}>
                                {Object.entries(groupedSessions).length > 1 && ( // Check if day is greater than 1
                                  <h3 className="font-lato font-semibold text-magenta-800">
                                    <FontAwesomeIcon
                                      icon={faChevronCircleRight}
                                      className="fa mr-2"
                                    />{" "}
                                    Day {day}
                                  </h3>
                                )}

                                {Object.entries(titles).map(
                                  ([title, sessions]) => {
                                    const breakoutSessions =
                                      sessions as EventBreakout[];

                                    return (
                                      <>
                                        {Object.entries(groupedSessions).length <= 1 && ( // Check if day is greater than 1
                                          <h3 className="font-lato font-semibold text-magenta-800 mb-6">
                                            <FontAwesomeIcon
                                              icon={faChevronCircleRight}
                                              className="fa mr-2"
                                            />{" "}
                                             {title}
                                          </h3>
                                        )}
                                        <div key={title} className="mb-7">
                                          <ul className="mx-4 my-3">
                                            <CMSFormRadioButtons
                                              name={title}
                                              label={
                                                Object.entries(groupedSessions).length > 1 ? title : ""
                                              }
                                              options={handleFormatArray(
                                                breakoutSessions
                                              )}
                                              form={form}
                                              required={
                                                details.activity
                                                  ?.breakoutSettings
                                                  ?.validationType ===
                                                "One For Each Day"
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </ul>
                                        </div>
                                      </>
                                    );
                                  }
                                )}
                              </div>
                            )
                          )}
                        </div>
                        <div className="pt-1 pb-2 mt-9 w-100 mx-10">
                          <button
                            type="button" // Prevents the default form submission
                            onClick={() =>
                              setAllContent((prevState) => ({
                                ...prevState,
                                peopleGroup: "",
                                company: "",
                                activeSection: "groupSelection",
                                data: {
                                  ...prevState.data,
                                  peopleGroup: "",
                                  partnerId: "",
                                  sponsorId: "",
                                },
                              }))
                            }
                            className="formSubmissionButton bg-textBlack hover:bg-black py-2 px-5 text-xl font-lato text-white mr-4"
                          >
                            BACK
                          </button>
                          <button
                            className="formSubmissionButton bg-magenta-800 hover:bg-magenta-900 py-2 text-xl font-lato px-5 text-white mr-4"
                            type="submit" // This submits the form
                          >
                            NEXT
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionSelection;
