import React, { FC, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { AllContentContext } from "src/context/AllContentContext";
const SuccessMessage: FC = () => {
  const context = useContext(AllContentContext);

  interface ContentItem {
    activity?: {
      title?: string;
      content?: {
        subTitle?: string;
      };
      welcomeScreen?: {
        showWelcomeScreen?: string;
        screenHeading?: string;
        screenMessage?: string;
      };
      formattedDate?: string;
      location?: {
        name?: string;
        country?: string;
      };
      peopleGroups?: [{ title?: string }];
      client?: {
        name?: string;
        logo?: string;
      };
      thankyouScreen?: {
        screenHeading?: string;
        screenMessage?: string;
      };
      partner?: [{ name?: string; _id?: string }];
      sponsor?: [{ name?: string; _id?: string }];
    };
  }
  if (!context) {
    return <div>Error: context is not available</div>;
  }

  const { allContent, setAllContent } = context;
  const details = allContent.content as ContentItem;
  const firstDetail = details;
  return (
    <>
      <div className="successMsg flex flex-col items-center justify-center py-10 my-10">
        <div className="animated_icon_holder"></div>
        <FontAwesomeIcon
          icon={faCheckCircle}
          className="fa text-5xl text-magenta-800 mb-5"
        />
        {details.activity && details.activity.thankyouScreen && (
          <>
            <p className="heading font-lato text-3xl text-magenta-800 font-base mb-2">
              {details.activity?.thankyouScreen?.screenHeading}
            </p>
            {typeof details.activity.thankyouScreen.screenMessage ===
            "string" ? (
              <p
                className="text-base font-lato"
                dangerouslySetInnerHTML={{
                  __html: details.activity.thankyouScreen.screenMessage,
                }}
              />
            ) : null}
          </>
        )}
        <div className="btn_holder btn btn-theme-secondary uppercase  bg-black hover:bg-black py-2 px-5 text-lg font-lato text-white mt-12">
          <div
            className="btn btn-theme-secondary cursor-pointer"
            onClick={() => {
              setAllContent((prevState) => ({
                ...prevState,
                loading: true,
              }));
              setTimeout(() => {
                setAllContent((prevState) => ({
                  ...prevState,
                  loading: true,
                  peopleGroup: "",
                  activeSection:
                    details.activity?.welcomeScreen?.showWelcomeScreen === "Yes"
                      ? "welcomeScreen"
                      : "groupSelection",
                  data: {},
                }));
              }, 400);
            }}
          >
            Register New
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessMessage;
